tarteaucitron.init({
  "privacyUrl": "", /* Privacy policy url */

  "hashtag": "#tarteaucitron", /* Open the panel with this hashtag */
  "cookieName": "tarteaucitron", /* Cookie name */

  "orientation": "bottom", /* Banner position (top - bottom) */
  "showAlertSmall": false, /* Show the small banner on bottom right */
  "cookieslist": true, /* Show the cookie list */
  "AcceptAllCta" : true, /* Show the accept all button when highPrivacy on */
  "DenyAllCta" : true,
  "adblocker": false, /* Show a Warning if an adblocker is detected */
  "highPrivacy": true, /* Disable auto consent */
  "handleBrowserDNTRequest": false, /* If Do Not Track == 1, disallow all */

  "removeCredit": false, /* Remove credit link */
  "moreInfoLink": true, /* Show more info link */
  "useExternalCss": false, /* If false, the tarteaucitron.css file will be loaded */

  //"cookieDomain": ".my-multisite-domaine.fr", /* Shared cookie for multisite */

  "readmoreLink": 'https://www.storistes-de-france.com/politique-de-gestion-des-cookies' /* Change the default readmore link */
});
